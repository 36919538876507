import React from "react"
import PropTypes from "prop-types"
import queryString from "query-string"

import SEO from "../components/seo"

import ProductListInfo from "../components/ProductList/ProductListInfo"
import StaticTextContainer from "../components/StaticTextContainer"

const SearchResults = ({ location, products, fetchProducts, isSearching }) => {
  const query = queryString.parse(location.search).q

  React.useEffect(() => {
    if (query) {
      fetchProducts(query)
    }
  }, [query])

  if (isSearching) {
    return (
      <StaticTextContainer
        title={`Looking for "${query}"`}
        body="Please wait for a moment"
      />
    )
  } else if (products.length) {
    return (
      <>
        <SEO
          path={`/search?q=${query}`}
          title="Search Product - Jessica Coppet"
        />
        <ProductListInfo
          title={`Results for "${query}"`}
          products={products}
          search={true}
        />
      </>
    )
  } else {
    return (
      <>
        <SEO
          path={`/search?q=${query}`}
          title="Search Product - Jessica Coppet"
        />
        <StaticTextContainer
          title={`No Results for "${query}"`}
          body="No we don't have any product for selected category"
        />
      </>
    )
  }
}

SearchResults.propTypes = {
  location: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  fetchProducts: PropTypes.func.isRequired,
}

export default SearchResults
